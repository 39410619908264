










































































































































































import {Component, Vue} from "vue-property-decorator";
import Toolbar from "@/components/Toolbar.vue";
import StruttureService, {Struttura, StruttureResponse} from "@/services/StruttureService";
import {DateTime} from "luxon";
import {Filtro} from "@/Classes/FiltroClass";
import EditMediaStructure from "@/components/EditMediaStructure.vue";
import GiorniChiusuraService, {GiorniChiusura, GiorniChiususuraResponse} from "@/services/GiorniChiusuraService";
import ModStructure from "@/views/ModStructure.vue";
import ServiziDiCortesiaService from "@/services/ServiziDiCortesiaService";

class Servizio{
  id: number

  public constructor() {
    this.id = 0
  }
}
interface Servizi{
  id:number,
  nome:string,
  descrizione:string,
  flag:boolean
}
class TabellaGiorni{
  dataInizio: string
  oraInizio: string
  dataFine: string
  oraFine: string

  public constructor() {
    this.dataInizio= ""
    this.oraInizio= ""
    this.dataFine= ""
    this.oraFine= ""
  }
}

@Component({
  components: {
    ModStructure,
    Toolbar,
    EditMediaStructure
  },
})
export default class Structure extends Vue {

  public strutturaSelezionata: Struttura = {
      id: 0,
      nome: "",
      descrizione: "",
      sito_web: "",
      telefono: "",
      email:"",
      orario_apertura:  [],
      orario_chiusura:  [],
      servizi_di_cortesia: [],
      annullabile: false,
      rimborsabile:false,
      ore_per_annullamento: 0,
      commento_obbligatorio: false,
      media:  [],
      comune:"",
      istat_comune: "",
      provincia: "",
      istat_provincia:"",
      indirizzo: "",
      logo: "",
      aperto: true,
      posizione:{
        type:"",
        coordinates:[]
      },
      id_subscription: "",
      subscription_price: 0,
      subscription_period_end:""
    }

  public idStruttura: number = 0
  public listaServizi: any[] = [];
  public listaServiziCompleta: Servizi[] = [];
  public listaServiziSelezionati:Servizio[] =[]
  public dialogServiziVisibile:boolean = false;
  public dialogMediaVisible:boolean = false;
  public dialogChiusureVisible:boolean = false
  public dialogModificaVisible: boolean = false
  public dialogOrariVisible: boolean=false
  public datiStruttura: StruttureResponse = []
  public orariStruttura: any = []
  public listaGiorniChiusura: GiorniChiusura = []
  public tabellaGiorniChiusura: TabellaGiorni[] = []
  public nuovaData: Date[] = []
  public giornoChiusura: GiorniChiususuraResponse = {
    start_time: "",
    end_time: ""
  }
  public dialogVisible:boolean = false;

  public giorniSettimana: string[] = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"]

  public filtro = new Filtro();

  fileList: [{ name: 'food.jpeg'; url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'; }, { name: 'food2.jpeg'; url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'; }] | undefined

  async SalvaServizi(){
    for(let i=0; i<this.listaServiziCompleta.length;i++){
      if(this.listaServiziCompleta[i].flag){
        let servizioTmp = this.listaServiziCompleta[i]
        this.listaServiziSelezionati.push(servizioTmp)
      }
    }
    const response = await ServiziDiCortesiaService.updateServiziDiCortesia(this.idStruttura, this.listaServiziSelezionati)
    if(response.error) {
      this.$message.error(response.msg)
    } else {
      location.reload()
    }
  }

  Modifica(strutturaSelezionata:Struttura){
    this.dialogModificaVisible = !this.dialogModificaVisible
    this.strutturaSelezionata = <Struttura>{}
    this.strutturaSelezionata = strutturaSelezionata
    console.log(this.strutturaSelezionata)
  }

  bloccaStruttura(id: number){
    if(this.datiStruttura[this.filtro.id_struttura].struttura.info.aperto){
      this.$confirm("Procedendo impedirai agli utenti di prenotare", "Sei sicuri di voler disabilitare la struttura ?", {
        confirmButtonText: "Conferma",
        cancelButtonText: "Indietro"
      }).then(async () => {
        const response = await StruttureService.bloccaStruttura(id)
        if(response.error) {
          this.$message.error(response.msg)
        } else {
          this.RiceviStrutture()
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Operazione annullata'
        })
      })
    } else {
      this.$confirm("Procedendo permetterai agli utenti di prenotare", "Vuoi riapreire la struttura ?", {
        confirmButtonText: "Conferma",
        cancelButtonText: "Indietro"
      }).then(async () => {
        const response = await StruttureService.bloccaStruttura(id)
        if(response.error) {
          this.$message.error(response.msg)
        } else {
          this.RiceviStrutture()
        }
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: 'Operazione annullata'
        })
      })
    }

  }

 async ServiziStruttura(strutturaSelezionata:Struttura){
    this.dialogServiziVisibile = !this.dialogServiziVisibile
   this.idStruttura = strutturaSelezionata.id
    const responseStruttura = await StruttureService.getStruttura(strutturaSelezionata.id)
   if(responseStruttura.error)
   {
     this.$message.error(responseStruttura.msg)
     return;
   }
   this.listaServizi = responseStruttura.data[0].struttura.info.servizi_di_cortesia;
   if(this.listaServiziCompleta.length === 0){
     const responseServizi = await ServiziDiCortesiaService.getServiziDiCortesia()
     if(responseServizi.error)
     {
       this.$message.error(responseServizi.msg)
       return;
     }
     for(const servizio of responseServizi.data) {
       let servizioTemp: Servizi = {
         id: servizio.id,
         nome: servizio.nome,
         descrizione: servizio.descrizione,
         flag: false
       }
       this.listaServiziCompleta.push(servizioTemp)
     }
   }
   for(const servizio of this.listaServiziCompleta) {
    for(const servizioEsistente of this.listaServizi){
      if(servizioEsistente.id === servizio.id){
        servizio.flag = true;
        break;
      }
    }
   }
 }

  public async RiceviStrutture(){
    try{
      const response = await StruttureService.getStrutture();
      console.log(response)
      if(response.error) {
        this.$message.error(response.msg)
      } else{
        this.datiStruttura = response.data;
        this.filtro.id_struttura = 0
      }
      //await this.RiceviGiorniChiusura();
    }
    catch (err:any)
    {

    }
  }

  async EliminaMedia(index:number)
  {
    try {
      const responseEliminaMedia = await StruttureService.deleteMediaStruttura(this.strutturaSelezionata.id,index);
      if(responseEliminaMedia.error)
      {
        this.$message.error(responseEliminaMedia.msg)
      }else {
        const responseStruttura = await StruttureService.getStruttura(this.strutturaSelezionata.id)
        if(!responseStruttura.error)
        {
          this.datiStruttura[index].struttura.info.media = responseStruttura.data[0].struttura.info.media;
          this.$message.success("Immagine eliminata con successo")
          this.strutturaSelezionata = <Struttura>{}
          this.strutturaSelezionata = responseStruttura.data[0].struttura.info;
        }
      }
    }catch (error:any)
    {

    }
  }

  async handleClose(done:any){
      this.$confirm('Sei sicuro di voler chiudere?')
      .then( async _ =>{
        const responseStruttura = await StruttureService.getStruttura(this.strutturaSelezionata.id)
        if(!responseStruttura.error)
        {
          for(let i =0; i<this.datiStruttura.length; i++){
            if(this.datiStruttura[i].struttura.info.id === responseStruttura.data[0].struttura.info.id)
            {
              this.datiStruttura[i].struttura.info.media = responseStruttura.data[0].struttura.info.media;
              break;
            }
          }
          this.strutturaSelezionata = <Struttura>{}
          this.strutturaSelezionata = responseStruttura.data[0].struttura.info;
        }
        done();
      })
      .catch(_ => {});
  }

  public async RiceviGiorniChiusura(){
    try{
      this.tabellaGiorniChiusura=[]
      const response = await GiorniChiusuraService.getGiorni(this.strutturaSelezionata.id)
      if(response.error) {
        this.$message.error(response.msg)
        this.dialogChiusureVisible = false
      } else{
        this.listaGiorniChiusura = response.data
        for(let i = 0; i<this.listaGiorniChiusura.length; i++) {
          this.tabellaGiorniChiusura.push({
            dataInizio: DateTime.fromISO(this.listaGiorniChiusura[i].start_time).toUTC().toLocaleString(),
            oraInizio: DateTime.fromISO(this.listaGiorniChiusura[i].start_time).toUTC().toLocaleString(DateTime.TIME_SIMPLE),
            dataFine: DateTime.fromISO(this.listaGiorniChiusura[i].end_time).toUTC().toLocaleString(),
            oraFine: DateTime.fromISO(this.listaGiorniChiusura[i].end_time).toUTC().toLocaleString(DateTime.TIME_SIMPLE)
          })
        }
      }
    }catch (err: any){

    }
  }

  public OrariChiusura(struttura: Struttura)
  {
    this.strutturaSelezionata = struttura
    this.dialogChiusureVisible= !this.dialogChiusureVisible;
    this.RiceviGiorniChiusura();
  }

  public mediaField():void{
    this.dialogVisible=true;

  }

  async VisualizzaOrari(row:any){
    this.orariStruttura= [];
    for (let j = 0; j < row.struttura.info.orario_apertura.length;j++)
    {
      this.orariStruttura.push({
        giorno:"",
        orarioAp: DateTime.fromFormat(row.struttura.info.orario_apertura[j], "HH:mm:ss+00").toFormat("HH:mm"),
        orarioCh: DateTime.fromFormat(row.struttura.info.orario_chiusura[j], "HH:mm:ss+00").toFormat("HH:mm")})
    }
    this.orariStruttura[0].giorno = "Lunedi"
    this.orariStruttura[1].giorno = "Martedi"
    this.orariStruttura[2].giorno = "Mercoledi"
    this.orariStruttura[3].giorno = "Giovedi"
    this.orariStruttura[4].giorno = "Venerdi"
    this.orariStruttura[5].giorno = "Sabato"
    this.orariStruttura[6].giorno = "Domenica"
    this.dialogOrariVisible = !this.dialogOrariVisible;
  }

  VisualizzaMedia(struttura: Struttura){
    this.strutturaSelezionata = <Struttura>{}
    this.strutturaSelezionata = struttura;
    console.log(this.strutturaSelezionata)
    this.dialogMediaVisible = !this.dialogMediaVisible
  }


  async Add(){
    let start = DateTime.fromJSDate(this.nuovaData[0]).toFormat("dd/MM/yyyy HH:mm:ss")
    //let DataIniziale = DateTime.utc(start.year,start.month,start.day,start.hour,start.minute,start.second).toString()
    let end = DateTime.fromJSDate(this.nuovaData[1]).toFormat("dd/MM/yyyy HH:mm:ss")
    //let DataFinale = DateTime.utc(end.year,end.month,end.day,end.hour,end.minute,end.second).toString()
    this.giornoChiusura = {
      start_time: start,
      end_time: end
    }
    const response = await GiorniChiusuraService.aggiungiGiorno(this.strutturaSelezionata.id, this.giornoChiusura)
    if(response.error){
      this.$message.error(response.msg)
    } else {
     await this.RiceviGiorniChiusura();
     this.nuovaData=[]
    }
  }

  async Elimina(indice: any){
    const response = await GiorniChiusuraService.deleteGiorni(this.strutturaSelezionata.id, this.listaGiorniChiusura[indice].id)
    if(response !== ""){
      this.$message.error(response.msg)
    }else {
      await this.RiceviGiorniChiusura()

    }
  }

  mounted(){
    this.RiceviStrutture();
  }

  beforeUpdate(){
   // this.setOrari()
  }
}
