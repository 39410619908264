import BaseService, { APIResponse } from "./BaseService";

export type GiorniChiususuraResponse = {
  start_time: string,
  end_time: string
}

export type GiorniChiusura = {
  id: number,
  start_time: string,
  end_time: string,
}[]

export default class GiorniChiusuraService extends BaseService {
  static async aggiungiGiorno(idStruttura: number, giorni: GiorniChiususuraResponse): Promise<APIResponse<GiorniChiususuraResponse>> {
    return await this.perform<GiorniChiususuraResponse>({
        url: "operatore/giornichiusura/" + idStruttura,
        method: "POST",
        body: giorni,
      }
    )
  }

  static async getGiorni(idStruttura: number): Promise<APIResponse<GiorniChiusura>>{
    return await this.perform<GiorniChiusura>({
      url: "operatore/giornichiusura/" + idStruttura,
      method: "GET",
    })
  }

  static async deleteGiorni(idStruttura: number, id: number): Promise<APIResponse<GiorniChiusura> | "">{
    return await this.perform<GiorniChiusura>({
      url: "operatore/giornichiusura/" + id,
      params:{ "id_struttura": idStruttura},
      method: "DELETE"
    })
  }
}
