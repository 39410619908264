













import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component
export default class EditMediaField extends Vue {
  @Prop() readonly struttura!: any
  @Prop() campo!: any
  public header:any={
    'Accept-Version': '1.0.0',
    'access_token':sessionStorage.getItem('access_token')
  }
  public fileList:any;
  public action:string = process.env.VUE_APP_URL_BACKEND+"/operatore/strutture/upload/"+this.struttura
  public handleRemove(file:any, fileList:any) {
    this.fileList = fileList;

  }
}
