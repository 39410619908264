import BaseService, { APIResponse } from "./BaseService";

export type ServiziDiCortesiaResponse = {
  id: number,
  nome: string,
  descrizione: string
}[]

export default class ServiziDiCortesiaService extends BaseService {
  static async getServiziDiCortesia(): Promise<APIResponse<ServiziDiCortesiaResponse>> {
    return await this.perform<ServiziDiCortesiaResponse>({
      url: 'operatore/servizi_di_cortesia',
      method: 'get'
    })
  }
  static async updateServiziDiCortesia(idStruttura: number, servizi: any[]): Promise<APIResponse<ServiziDiCortesiaResponse>> {
    return await this.perform<ServiziDiCortesiaResponse>({
      url:'operatore/servizi_di_cortesia',
      method:'post',
      params:{'id_struttura': idStruttura},
      body: servizi
    })
  }
}
